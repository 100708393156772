
import LanguageService from '../../service/LanguageService'
let model = {};

// dữ liệu form
model.dataForm = {
  id: undefined, // required - Mã tự sinh
  account: undefined, // required - Tài khoản
  password: undefined, // optional - Mật khẩu
  disabled: undefined, // optional - Cho biết tài khoản có bị khóa đăng nhập hay không
  roles: [], // required, array items: required - Danh sách các quyền người dùng
};

model.breadcrumbDanhSachNguoiDung = [
  {
    text: "Trang chủ",
  },
  {
    text: "Quản lý người dùng",
  },
  {
    text: "Danh sách người dùng",
  },
];

model.optionsRolesAdmin = [
  {
    value: "Admin",
    label: "Super Admin",
  },
  {
    value: "AdminPrimary",
    label: "Admin",
  },
  {
    value: "AdminLocal",
    label: "Admin Local",
  },
  {
    value: "Edit",
    label: "Edit",
  },
  {
    value: "User",
    label: "View",
  },
];
model.optionsRolesAdminPrimary = [
  {
    value: "AdminPrimary",
    label: "Admin",
  },
  {
    value: "AdminLocal",
    label: "Admin Local",
  },
  {
    value: "Edit",
    label: "Edit",
  },
  {
    value: "User",
    label: "View",
  },
];
model.optionsUser = [
  {
    value: "true",
    label: LanguageService?.lang?.t_block_account ?? "t_block_account",
  },
  {
    value: "false",
    label: LanguageService?.lang?.t_active_account ?? "t_active_account",
  },
];
model.tableRules = {
  lengthMenu: [10, 20, 50, 100],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 20,
  offset: 0,
  sort: "account",
  defaultSort: ["account", "ascending"],
  fields: "account,disabled,roles",
  filters: "",
  dataSearch: {
    value: {
      account: "",
      roles: "",
      disabled: "",
    },
    operator: {
      account: ":regex_i:",
      roles: "",
      disabled: "",
    },
  },
};

export default model;
